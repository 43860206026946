import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Table } from 'antd';
import TableConfig from './TableConfig';
import TableTitle from './TableTitle';
import Preloader from '../../../../../components/preloader/Preloader';
import { PreloaderBox } from '../../../../../../../common/ui/Common';

function DetailedReport({ settings, preloader }) {
  const [ReportParams, setReportParams] = useState();
  const { startDate, endDate } = settings;
  const fetchReportData = async () => {
    preloader();
    const url = '/api/reports/build';
    const type = 'qualityDetailedReport';
    const token = window.localStorage.getItem('token');
    const res = await axios({
      method: 'post',
      url,
      data: {
        token,
        filters: { main: { startDate, endDate } },
        type,
      },
    });
    console.log('res === ', res);

    const params = {
      filters: { main: { startDate, endDate } },
      items: res.data.answer.myData,
      TableConfig: TableConfig(res.data.answer.queryList.data),
      TebleType: 'DetailedReport',
    };
    setReportParams(params);
    preloader();
  };

  useEffect(() => {
    fetchReportData();
  }, [settings]);

  return (
    ReportParams ? (
      <>
        <TableTitle
          ReportParams={ReportParams}
          name="Детализированный отчет по каждой оценке"
        />
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          filename={`Детализированный отчет ${startDate} - ${endDate}`}
          table="table-to-xls"
          sheet="tablexls"
          buttonText="Экспортировать"
        />
        <Table
          dataSource={ReportParams.items}
          columns={ReportParams.TableConfig.columns}
          scroll={{ x: `${ReportParams.TableConfig.columns.length * 100}` }}
        />
      </>
    ) : (
      <PreloaderBox>
        <Preloader isOpen small />
      </PreloaderBox>
    )
  );
}

export default DetailedReport;
